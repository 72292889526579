import _ from "lodash";
import {
  UPDATE_FIELD_VALUE,
  GET_CATALOG,
  GET_WS_DATA,
  REMOVE_FILE,
  SIGN_FILE,
  UPLOAD_FILE,
  FILL_WS_DATA,
  POST_WS_DATA,
  RETURN_WS_DATA,
  UPDATE_TABLE_FIELD_VALUE,
  SET_ERRORMESSAGE_BY_ID,
  GET_REGISTRY_CATALOG
} from "./action-types";

import {
  SET_WS_DATA,
  SET_DOCS_DATA,
  ADD_CATALOG_LIST,
  SET_FILE_TO_DOC,
  SET_SIGNED_FILE,
  SET_FIELDS_VALUE,
  CLEAR_DIRTY_FIELDS_VALUE,
  RESET,
  SET_ERRORS,
  CLEAR_DIRTY_DOCS,
  SET_FIELD_VALUE_BY_ID,
  SET_AUTOFIELD_FUNCTIONS,
  SET_FIELD_DIRTY_BY_ID,
  SET_SUCCESS_GENERATE,
  SET_ERROR_BY_ID,
  SET_NEXT_WS_EMP,
  TOGGLE_LOADER_MODAL
} from "./mutation-types";

const inputFieldInitValues = {
  table: [],
  checkbox: false,
};

const autofieldCalculation = (
  $axios,
  asId,
  params,
  callback,
  catchFn = console.log
) => {
  $axios
    .post(`api/application/event/${asId}`, params)
    .then((res) => callback && callback.call && callback(res.data))
    .catch((...args) => catchFn?.call && catchFn(...args));
};

const getValuesForFill = (state, getters) => {
  return getters.inputFields
    .filter((field) => field.type !== "static_text")
    .reduce(
      (acc, field) => ({
        ...acc,
        [field._id]: state.fieldsValue[field._id].value,
      }),
      {}
    );
};

const getDiff = (curr, prev) => {
  if (!Array.isArray(curr) || !Array.isArray(prev)) {
    return;
  }

  if (curr.length != prev.length) {
    if (curr.length > 0) {
      return Object.keys(curr[0]);
    }

    if (prev.length > 0) {
      return Object.keys(prev[0]);
    }
  }

  return curr.reduce((acc, val, key) => {
    let res = [];

    if (_.isEqual(val, prev?.[key])) return acc;
    if (_.isObject(val) && (!prev?.[key] || _.isObject(prev?.[key]))) {
      res = _.map(val, (colVal, col) => {
        if (_.isEqual(colVal, prev?.[key]?.[col])) return;
        return col;
      }).filter((f) => f);
    }

    return _.uniq([...acc, ...(res || [])]);
  }, []);
};

export default {
  [UPDATE_FIELD_VALUE]({ commit, dispatch, state, getters }, { id, value }) {
    const prevVal = _.cloneDeep(state.fieldsValue?.[id]?.value);

    commit(SET_FIELD_VALUE_BY_ID, { id, value });

    const triggers = state.wsData.event_triggers;
    const afFunctions = state.autofieldFunctions;

    if (!_.isPlainObject(triggers) && _.isEmpty(triggers)) {
      return;
    }

    if (!_.isPlainObject(afFunctions) && _.isEmpty(afFunctions)) {
      return;
    }

    const autofieldsId = _.toPairs(triggers)
      .filter(([__, triggerFields]) => {
        if (!Array.isArray(triggerFields)) return;

        const filteredByCurrId = triggerFields.filter((i) => i.includes(id));

        if (!filteredByCurrId.length) return;

        if (Array.isArray(value)) {
          const arrValDiff = getDiff(value, prevVal);

          if (!_.isArray(arrValDiff) || !arrValDiff.length) {
            return false;
          }

          const triggerInDiff = filteredByCurrId
            .map((i) => i.replace(`${id}.`, ""))
            .some((i) => arrValDiff.includes(i));

          if (!triggerInDiff) return false;
        }

        const afTriggersFilled = triggerFields.every((tfId) => {
          let fieldId = tfId;
          const splitted = tfId.split(".");
          if (splitted.length == 2) {
            fieldId = splitted[0];

            const tableVal = state.fieldsValue?.[splitted[0]].value;

            if (!tableVal) return false;

            const allEmpty = tableVal.every(
              r => _.isNil(r?.[splitted[1]]) || r?.[splitted[1]] === ''
            )

            if (allEmpty) return false;
          } else {
            let val = state.fieldsValue[tfId]?.value;

            if (Array.isArray(val) && !val.length) return false;

            if (_.isNil(val) || val === "") return false;
          }

          if (!(fieldId in state.fieldsValue)) return false;

          if (!state.fieldsValue[fieldId].dirty) return false;

          return true;
        });

        if (!afTriggersFilled) return;

        return true;
      })
      .map(([key, __]) => key);

    autofieldsId.forEach((afId) => {
      if (!(afId in afFunctions)) {
        return;
      }

      afFunctions[afId](
        this.$axios,
        getters.asId,
        {
          autofield_formula_id: afId,
          fields_values: {
            ...getValuesForFill(state, getters),
            [id]: value,
          },
        },
        (afRes) => {
          if (!_.isPlainObject(afRes) && _.isEmpty(afRes)) return;

          _.toPairs(afRes).forEach(([id, value]) => {
            if (Array.isArray(value)) {
              dispatch(UPDATE_TABLE_FIELD_VALUE, { id, value });
            } else {
              dispatch(UPDATE_FIELD_VALUE, { id, value });
            }
          });
        },
        (e) => {
          if (!e.response?.data?.message) {
            console.log(e);
          }

          commit(SET_ERRORS, {
            ...state.error,
            [id]: e.response?.data?.message ?? "",
          });
          commit(SET_FIELD_DIRTY_BY_ID, { id, dirty: false });
        }
      );
    });
  },
  async [GET_WS_DATA]({ commit, getters, dispatch }, { asId, fieldsQuery }) {
    commit(RESET);

    const resp = await this.$axios.get("api/application/get/" + asId);

    commit(SET_WS_DATA, resp.data);
    commit(SET_DOCS_DATA, resp.data.docs);

    Object.keys(getters.nextWses).forEach((wsId) => {
      commit(SET_NEXT_WS_EMP, { wsId, empId: null });
    });

    const functions = _.keys(resp.data.event_triggers).reduce(
      (acc, afId) => ({
        ...acc,
        [afId]: _.debounce(autofieldCalculation, 3000),
      }),
      {}
    );

    commit(SET_AUTOFIELD_FUNCTIONS, { functions });

    const inputFieldValues = {};

    for (const field of resp.data.input_fields) {
      if (field.type === "static_text") continue;

      let value = null;

      if (fieldsQuery.hasOwnProperty(field._id)) {
        if (["point", "line", "polygon"].includes(field.type)) {
          let geojson = JSON.parse(fieldsQuery[field._id]);
          geojson.properties.inputType = "draw";
          geojson.id = field._id;
          value = geojson;
        } else {
          value = fieldsQuery[field._id];
        }
      }

      if (field.hasOwnProperty("value")) {
        value = field.value;
      } else if (field.type in inputFieldInitValues) {
        value = inputFieldInitValues[field.type];
      }

      inputFieldValues[field._id] = {
        value: value,
        dirty: false,
      };
    }

    commit(SET_FIELDS_VALUE, inputFieldValues);

    for (const field of resp.data.input_fields) {
      if (field.type === 'table') {
        for (const col of field.columns) {
          if (col.type === 'catalog') {
            await dispatch(GET_CATALOG, { catalogId: col.catalog });
          }
          if (col.type === 'registry_catalog') {
            await dispatch(GET_REGISTRY_CATALOG, {
              catalogName: col.registry_catalog || col.registry,
              asId,
              fieldId: field._id,
              column: col.key,
              langs: col.registry_catalog_lang_values
            });
          }
        }
      }

      if (field.type === 'catalog') {
        await dispatch(GET_CATALOG, { catalogId: field.catalog_id });
      }
      if (field.type === 'registry_catalog') {
        await dispatch(GET_REGISTRY_CATALOG, {
          catalogName: field.registry_catalog || field.registry,
          asId,
          fieldId: field.field_id,
          langs: field.registry_catalog_lang_values
        });
      }
    }

    for (const field of resp.data.view_fields) {
      if (field.type === 'table') {
        for (const col of field.columns) {
          if (col.type === 'catalog') {
            await dispatch(GET_CATALOG, { catalogId: col.catalog });
          }
          if (col.type === 'registry_catalog') {
            await dispatch(GET_REGISTRY_CATALOG, {
              catalogName: col.registry_catalog || col.registry,
              asId,
              fieldId: field.field_id,
              column: col.key,
              langs: col.registry_catalog_lang_values
            });
          }
        }
      }

      if (field.type === 'catalog') {
        await dispatch(GET_CATALOG, { catalogId: field.catalog_id });
      }
      if (field.type === 'registry_catalog') {
        await dispatch(GET_REGISTRY_CATALOG, {
          catalogName: field.registry_catalog || field.registry,
          asId,
          fieldId: field.field_id,
          langs: field.registry_catalog_lang_values
        });
      }
    }

  },
  [UPDATE_TABLE_FIELD_VALUE]({ dispatch, state }, { id, value }) {
    const currVal = state.fieldsValue?.[id]?.value;
    const clone =
      (currVal || [])?.map?.((r) => ({
        ...((_.isPlainObject(r) && r) || {}),
      })) || [];

    dispatch(UPDATE_FIELD_VALUE, {
      id,
      value: _.merge(clone, value),
    });
  },
  async [FILL_WS_DATA]({ state, commit, getters }) {
    let fieldsData = getValuesForFill(state, getters);

    if (getters.wsType == "one") {
      fieldsData["next_ws"] = getters.nextWs;
    }

    const fillUrl = `api/application/fill/${getters.asId}`;
    commit(SET_SUCCESS_GENERATE, false);
    commit(TOGGLE_LOADER_MODAL, true);
    const fillPromise = this.$axios.post(fillUrl, fieldsData).then((resp) => {
      let genDocs = resp.data;
      commit(SET_SUCCESS_GENERATE, true);
      
      genDocs.forEach((file) => {
        commit(SET_FILE_TO_DOC, {
          docId: file.input_doc_id,
          file: file,
        });
      });
    })
    .finally(() => commit(TOGGLE_LOADER_MODAL, false));

    if (!getters.hasGenerateDocs) {
      return fillPromise;
    }

    return fillPromise
      .catch((error) => {
        commit(SET_ERRORS, error.response.data);
        return Promise.reject(error);
      })
      .finally(() => {
        commit(TOGGLE_LOADER_MODAL, false);
        commit(CLEAR_DIRTY_FIELDS_VALUE);
      });
  },
  async [POST_WS_DATA]({ commit, getters, dispatch }) {
    const postUrl = `api/application/post/${getters.asId}`;

    let fillPromise = Promise.resolve();

    if (!getters.hasGenerateDocs) {
      fillPromise = fillPromise.then(() => {
        return dispatch(FILL_WS_DATA);
      });
    }
    commit(TOGGLE_LOADER_MODAL, true);
    return fillPromise
      .then(() => {
        return this.$axios.post(postUrl, {
          next_ws: getters.wsType == "one" ? getters.nextWs : null,
          next_ws_emps: getters.nextWsEmps
        });
      })
      .then(() => {
        commit(RESET);
        let redirectUrl = "/base/application/list";

        if (this.$auth.user.role == 3) {
          redirectUrl = "/arm/application/income";
        }

        this.$router.push(redirectUrl);
      })
      .catch((error) => {
        commit(SET_ERRORS, error?.response?.data);
      })
      .finally(() => {
        commit(CLEAR_DIRTY_DOCS);
        commit(CLEAR_DIRTY_FIELDS_VALUE);
        commit(TOGGLE_LOADER_MODAL, false);
      });
  },
  [GET_CATALOG]: async function({ commit, state }, { catalogId }) {
    if (!state.catalogsList.hasOwnProperty(catalogId)) {
      const formatValue = (data) => {
        return data.map(item => ({
          val: item._id,
          name: item.name,
          childOptions: formatValue(item.all_children)
        }))
      };

      return this.$axios
        .get("api/catalog", {
          params: {
            catalog_id: catalogId,
          },
        })
        .then((resp) => {
          return commit(ADD_CATALOG_LIST, {
            catalogId: catalogId,
            catalog: formatValue(resp.data),
          });
        });
    }
  },

  [GET_REGISTRY_CATALOG]: async function({ commit, state }, { catalogName, asId, fieldId, column, langs}) { 
    if (!state.catalogsList.hasOwnProperty(catalogName)) {
      const formatValue = (data) => {
        return data.map(item => ({
          val: item.id,
          name: Object.keys(langs)
            .reduce((res, lang) => ({
              ...res,
              [lang]: item[langs[lang]]
            }), {})
        }))
      };

      return this.$axios
        .get(`api/application/registry/${asId}`, {
          params: {
            input_field_id: fieldId,
            col: column
          }
        })
        .then((resp) => {
          return commit(ADD_CATALOG_LIST, {
            catalogId: catalogName,
            catalog: formatValue(resp.data),
          });
        });
    }
  },
  async [UPLOAD_FILE]({ commit, getters }, { docId, data }) {
    const url = `api/egkn/application/upload/file/${getters.asId}/${docId}`;

    return this.$axios.post(url, data).then((resp) => {
      commit(SET_FILE_TO_DOC, {
        docId: docId,
        file: resp.data,
      });
    });
  },
  async [REMOVE_FILE]({ commit, getters }, { docId }) {
    const url = `api/application/remove/file/${getters.asId}/${docId}`;

    this.$axios.post(url).then((__) => {
      commit(SET_FILE_TO_DOC, {
        docId: docId,
        file: null,
      });
    });
  },
  async [SIGN_FILE]({ commit, dispatch, getters }, { docId, fileId, type }) {
    const getFileBase64Url = `api/application/file/get/base64/${getters.asId}/${docId}/${fileId}`;

    const uploadSignUrl = `api/application/upload/sign/${getters.asId}/${docId}/${fileId}`;

    return this.$axios
      .get(getFileBase64Url)
      .then((resp) => {
        const fileContentBase64 = resp.data;

        return dispatch("eds/signCMS", fileContentBase64, { root: true });
      })
      .then((resp) => {
        const signedData = resp.responseObject;

        return this.$axios.post(uploadSignUrl, { sign: signedData });
      })
      .then((resp) => {
        commit(SET_SIGNED_FILE, {
          docId: docId,
          signedFile: resp.data,
          type: type,
        });
      });
  },
  async [RETURN_WS_DATA]({ getters }, { returnMessage }) {
    const returnUrl = `api/application/return/${getters.asId}`;

    this.$axios
      .post(returnUrl, { return_message: returnMessage })
      .then(() => {
        let redirectUrl = "/base/application/list";

        if (this.$auth.user.role == 3) {
          redirectUrl = "/arm/application/income";
        }

        this.$router.push(redirectUrl);
      })
      .catch((error) => {
        alert(error);
      });
  },
  [SET_ERRORMESSAGE_BY_ID]({ commit }, { docId, error }) {
    commit(SET_ERROR_BY_ID, {
      id: docId,
      error: error,
    });
  },
};
