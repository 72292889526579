import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5fdc980c = () => interopDefault(import('../uicore/pages/arm/index.vue' /* webpackChunkName: "pages/arm/index" */))
const _53287789 = () => interopDefault(import('../uicore/pages/base/index.vue' /* webpackChunkName: "pages/base/index" */))
const _516a86e7 = () => interopDefault(import('../uicore/pages/Test.vue' /* webpackChunkName: "pages/Test" */))
const _141302dc = () => interopDefault(import('../uicore/pages/arm/forgotPass.vue' /* webpackChunkName: "pages/arm/forgotPass" */))
const _cc8d51de = () => interopDefault(import('../uicore/pages/arm/login.vue' /* webpackChunkName: "pages/arm/login" */))
const _0becad51 = () => interopDefault(import('../uicore/pages/arm/profile.vue' /* webpackChunkName: "pages/arm/profile" */))
const _59acd38d = () => interopDefault(import('../uicore/pages/base/faq.vue' /* webpackChunkName: "pages/base/faq" */))
const _32cbf305 = () => interopDefault(import('../uicore/pages/oldAppsList/applicant.vue' /* webpackChunkName: "pages/oldAppsList/applicant" */))
const _41f9a67b = () => interopDefault(import('../uicore/pages/oldAppsList/employee.vue' /* webpackChunkName: "pages/oldAppsList/employee" */))
const _47dd2a50 = () => interopDefault(import('../uicore/pages/arm/application/income.vue' /* webpackChunkName: "pages/arm/application/income" */))
const _6ae5e2f6 = () => interopDefault(import('../uicore/pages/arm/application/list.vue' /* webpackChunkName: "pages/arm/application/list" */))
const _3ae9cb96 = () => interopDefault(import('../uicore/pages/base/application/list.vue' /* webpackChunkName: "pages/base/application/list" */))
const _445f17dc = () => interopDefault(import('../uicore/pages/base/auth/forgotPass.vue' /* webpackChunkName: "pages/base/auth/forgotPass" */))
const _bcc907ca = () => interopDefault(import('../uicore/pages/base/auth/login.vue' /* webpackChunkName: "pages/base/auth/login" */))
const _b84c904a = () => interopDefault(import('../uicore/pages/base/auth/profile.vue' /* webpackChunkName: "pages/base/auth/profile" */))
const _2b4062e1 = () => interopDefault(import('../uicore/pages/base/auth/register.vue' /* webpackChunkName: "pages/base/auth/register" */))
const _01a1dede = () => interopDefault(import('../uicore/pages/arm/admin/org/app.vue' /* webpackChunkName: "pages/arm/admin/org/app" */))
const _746ec6c1 = () => interopDefault(import('../uicore/pages/arm/admin/org/employee.vue' /* webpackChunkName: "pages/arm/admin/org/employee" */))
const _1833e6f2 = () => interopDefault(import('../uicore/pages/arm/admin/org/service.vue' /* webpackChunkName: "pages/arm/admin/org/service" */))
const _9afca0ee = () => interopDefault(import('../uicore/pages/arm/admin/system/app.vue' /* webpackChunkName: "pages/arm/admin/system/app" */))
const _468614f4 = () => interopDefault(import('../uicore/pages/arm/admin/system/calendar.vue' /* webpackChunkName: "pages/arm/admin/system/calendar" */))
const _6a6d1676 = () => interopDefault(import('../uicore/pages/arm/admin/system/control.vue' /* webpackChunkName: "pages/arm/admin/system/control" */))
const _aa245a34 = () => interopDefault(import('../uicore/pages/arm/admin/system/list.vue' /* webpackChunkName: "pages/arm/admin/system/list" */))
const _0860ffb1 = () => interopDefault(import('../uicore/pages/arm/admin/system/route.vue' /* webpackChunkName: "pages/arm/admin/system/route" */))
const _10a88440 = () => interopDefault(import('../uicore/pages/arm/application/view/asId.vue' /* webpackChunkName: "pages/arm/application/view/asId" */))
const _33f4d0bc = () => interopDefault(import('../uicore/pages/base/application/result/appId.vue' /* webpackChunkName: "pages/base/application/result/appId" */))
const _7f3c80a5 = () => interopDefault(import('../uicore/pages/arm/admin/org/ws/employee/_empId.vue' /* webpackChunkName: "pages/arm/admin/org/ws/employee/_empId" */))
const _60621742 = () => interopDefault(import('../uicore/pages/arm/admin/org/ws/_tsrId.vue' /* webpackChunkName: "pages/arm/admin/org/ws/_tsrId" */))
const _a903acce = () => interopDefault(import('../uicore/pages/arm/application/monitor/_appId.vue' /* webpackChunkName: "pages/arm/application/monitor/_appId" */))
const _cdbb4d56 = () => interopDefault(import('../uicore/pages/arm/application/process/_asId.vue' /* webpackChunkName: "pages/arm/application/process/_asId" */))
const _c5544ac4 = () => interopDefault(import('../uicore/pages/arm/service/info/_tsrId.vue' /* webpackChunkName: "pages/arm/service/info/_tsrId" */))
const _129177b5 = () => interopDefault(import('../uicore/pages/arm/service/list/_parentId.vue' /* webpackChunkName: "pages/arm/service/list/_parentId" */))
const _1402856a = () => interopDefault(import('../uicore/pages/base/application/monitor/_appId.vue' /* webpackChunkName: "pages/base/application/monitor/_appId" */))
const _41aabf64 = () => interopDefault(import('../uicore/pages/base/application/process/_asId.vue' /* webpackChunkName: "pages/base/application/process/_asId" */))
const _5a582327 = () => interopDefault(import('../uicore/pages/base/auth/resetPass/_token.vue' /* webpackChunkName: "pages/base/auth/resetPass/_token" */))
const _556bb7ed = () => interopDefault(import('../uicore/pages/base/service/info/_tsrId.vue' /* webpackChunkName: "pages/base/service/info/_tsrId" */))
const _4af66406 = () => interopDefault(import('../uicore/pages/base/service/list/_parentId.vue' /* webpackChunkName: "pages/base/service/list/_parentId" */))
const _6cba1b1e = () => interopDefault(import('../uicore/pages/arm/resetPass/_token.vue' /* webpackChunkName: "pages/arm/resetPass/_token" */))
const _e6350902 = () => interopDefault(import('../uicore/pages/base/check_doc/_fileId.vue' /* webpackChunkName: "pages/base/check_doc/_fileId" */))
const _7d49f16d = () => interopDefault(import('../uicore/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _1c265a74 = () => interopDefault(import('../modules/Egkn/pages/arm/index.vue' /* webpackChunkName: "" */))
const _3534a4b8 = () => interopDefault(import('../modules/Egkn/pages/arm/actualization.vue' /* webpackChunkName: "" */))
const _464b8898 = () => interopDefault(import('../modules/Egkn/pages/arm/actualizationMap.vue' /* webpackChunkName: "" */))
const _ecbfd636 = () => interopDefault(import('../modules/Egkn/pages/arm/auction.vue' /* webpackChunkName: "" */))
const _7336d00b = () => interopDefault(import('../modules/Egkn/pages/arm/registry.vue' /* webpackChunkName: "" */))
const _4f5cb15f = () => interopDefault(import('../modules/Egkn/pages/arm/statistica.vue' /* webpackChunkName: "" */))
const _ecaebf62 = () => interopDefault(import('../modules/Egkn/pages/base/index.vue' /* webpackChunkName: "" */))
const _492cdf93 = () => interopDefault(import('../modules/Egkn/pages/base/actualization.vue' /* webpackChunkName: "" */))
const _c0af6000 = () => interopDefault(import('../modules/Egkn/pages/base/auction.vue' /* webpackChunkName: "" */))
const _1958e4e7 = () => interopDefault(import('../modules/Egkn/pages/index.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/arm",
    component: _5fdc980c,
    name: "arm"
  }, {
    path: "/base",
    component: _53287789,
    name: "base"
  }, {
    path: "/Test",
    component: _516a86e7,
    name: "Test"
  }, {
    path: "/arm/forgotPass",
    component: _141302dc,
    name: "arm-forgotPass"
  }, {
    path: "/arm/login",
    component: _cc8d51de,
    name: "arm-login"
  }, {
    path: "/arm/profile",
    component: _0becad51,
    name: "arm-profile"
  }, {
    path: "/base/faq",
    component: _59acd38d,
    name: "base-faq"
  }, {
    path: "/oldAppsList/applicant",
    component: _32cbf305,
    name: "oldAppsList-applicant"
  }, {
    path: "/oldAppsList/employee",
    component: _41f9a67b,
    name: "oldAppsList-employee"
  }, {
    path: "/arm/application/income",
    component: _47dd2a50,
    name: "arm-application-income"
  }, {
    path: "/arm/application/list",
    component: _6ae5e2f6,
    name: "arm-application-list"
  }, {
    path: "/base/application/list",
    component: _3ae9cb96,
    name: "base-application-list"
  }, {
    path: "/base/auth/forgotPass",
    component: _445f17dc,
    name: "base-auth-forgotPass"
  }, {
    path: "/base/auth/login",
    component: _bcc907ca,
    name: "base-auth-login"
  }, {
    path: "/base/auth/profile",
    component: _b84c904a,
    name: "base-auth-profile"
  }, {
    path: "/base/auth/register",
    component: _2b4062e1,
    name: "base-auth-register"
  }, {
    path: "/arm/admin/org/app",
    component: _01a1dede,
    name: "arm-admin-org-app"
  }, {
    path: "/arm/admin/org/employee",
    component: _746ec6c1,
    name: "arm-admin-org-employee"
  }, {
    path: "/arm/admin/org/service",
    component: _1833e6f2,
    name: "arm-admin-org-service"
  }, {
    path: "/arm/admin/system/app",
    component: _9afca0ee,
    name: "arm-admin-system-app"
  }, {
    path: "/arm/admin/system/calendar",
    component: _468614f4,
    name: "arm-admin-system-calendar"
  }, {
    path: "/arm/admin/system/control",
    component: _6a6d1676,
    name: "arm-admin-system-control"
  }, {
    path: "/arm/admin/system/list",
    component: _aa245a34,
    name: "arm-admin-system-list"
  }, {
    path: "/arm/admin/system/route",
    component: _0860ffb1,
    name: "arm-admin-system-route"
  }, {
    path: "/arm/application/view/asId",
    component: _10a88440,
    name: "arm-application-view-asId"
  }, {
    path: "/base/application/result/appId",
    component: _33f4d0bc,
    name: "base-application-result-appId"
  }, {
    path: "/arm/admin/org/ws/employee/:empId?",
    component: _7f3c80a5,
    name: "arm-admin-org-ws-employee-empId"
  }, {
    path: "/arm/admin/org/ws/:tsrId?",
    component: _60621742,
    name: "arm-admin-org-ws-tsrId"
  }, {
    path: "/arm/application/monitor/:appId?",
    component: _a903acce,
    name: "arm-application-monitor-appId"
  }, {
    path: "/arm/application/process/:asId?",
    component: _cdbb4d56,
    name: "arm-application-process-asId"
  }, {
    path: "/arm/service/info/:tsrId?",
    component: _c5544ac4,
    name: "arm-service-info-tsrId"
  }, {
    path: "/arm/service/list/:parentId?",
    component: _129177b5,
    name: "arm-service-list-parentId"
  }, {
    path: "/base/application/monitor/:appId?",
    component: _1402856a,
    name: "base-application-monitor-appId"
  }, {
    path: "/base/application/process/:asId?",
    component: _41aabf64,
    name: "base-application-process-asId"
  }, {
    path: "/base/auth/resetPass/:token?",
    component: _5a582327,
    name: "base-auth-resetPass-token"
  }, {
    path: "/base/service/info/:tsrId?",
    component: _556bb7ed,
    name: "base-service-info-tsrId"
  }, {
    path: "/base/service/list/:parentId?",
    component: _4af66406,
    name: "base-service-list-parentId"
  }, {
    path: "/arm/resetPass/:token?",
    component: _6cba1b1e,
    name: "arm-resetPass-token"
  }, {
    path: "/base/check_doc/:fileId?",
    component: _e6350902,
    name: "base-check_doc-fileId"
  }, {
    path: "/",
    component: _7d49f16d,
    name: "index"
  }, {
    path: "/arm",
    component: _1c265a74,
    name: "arm"
  }, {
    path: "/arm/actualization",
    component: _3534a4b8,
    name: "arm-actualization"
  }, {
    path: "/arm/actualizationMap",
    component: _464b8898,
    name: "arm-actualizationMap"
  }, {
    path: "/arm/auction",
    component: _ecbfd636,
    name: "arm-auction"
  }, {
    path: "/arm/registry",
    component: _7336d00b,
    name: "arm-registry"
  }, {
    path: "/arm/statistica",
    component: _4f5cb15f,
    name: "arm-statistica"
  }, {
    path: "/base",
    component: _ecaebf62,
    name: "base"
  }, {
    path: "/base/actualization",
    component: _492cdf93,
    name: "base-actualization"
  }, {
    path: "/base/auction",
    component: _c0af6000,
    name: "base-auction"
  }, {
    path: "/",
    component: _1958e4e7
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
