import { render, staticRenderFns } from "./arm.vue?vue&type=template&id=2c3e3ce7&"
import script from "./arm.vue?vue&type=script&lang=js&"
export * from "./arm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ArmHeader: require('/var/www/egkn/ui/modules/Egkn/components/Arm/Header.vue').default,ArmFooter: require('/var/www/egkn/ui/modules/Egkn/components/Arm/Footer.vue').default})
