//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      mainMenu: false,
      authMenu: false,
    };
  },
  created() {
    const onClickOutside = (e) => {
      this.authMenu = this.$el.contains(e.target) && this.authMenu;
    };
    document.addEventListener("click", onClickOutside);
    this.$on("hook:beforeDestroy", () =>
      document.removeEventListener("click", onClickOutside)
    );
  },
  computed: {
    availableLocales() {
      return this.$i18n.locales;
    },
    currentLocale() {
      return this.$i18n.locales.find((i) => i.code === this.$i18n.locale);
    },
    menuItems() {
      return this.$store.state.headerArm;
    },
    authMenuItems() {
      return this.$store.getters.allowedArmUserMenuItems;
    },
  },
  methods: {
    redirectToLogin() {
      this.$router.push("/base");
    },
    async logout() {
      await this.$auth.logout();
      this.$router.push("/base");
    },
    changeLocale(newLocale) {
      this.$i18n.setLocale(newLocale);
      this.$nuxt.$emit("changeLocale", newLocale);
    },
    getAvatarLetter(){
      return (this.$auth?.user?.full_name).trim()[0]
    },
    goToProfile(){
      this.$router.push("/arm/application/list");
    }
  },
};
