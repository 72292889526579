import { render, staticRenderFns } from "./base.vue?vue&type=template&id=c2144f10&"
import script from "./base.vue?vue&type=script&lang=js&"
export * from "./base.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseHeader: require('/var/www/egkn/ui/modules/Egkn/components/Base/Header.vue').default,BaseFooter: require('/var/www/egkn/ui/modules/Egkn/components/Base/Footer.vue').default})
