import {
  INIT_MAP,
  TOGGLE_BASEMAP,
  SET_BASEMAPS,
  REMOVE_INTERACTION_BY_TYPE
} from "./action-types";
import {
  SET_MAP,
  SET_BASEMAPS as SET_BASEMAPS_MUTATION,
  SET_ACTIVE_BASEMAP,
  CLEAR_INTERACTION_TYPE
} from "./mutation-types";

import olInitMap from "~/assets/js/olInitMap";

export default {
  [INIT_MAP]({ commit, state }, { el, initialFocus }) {
    const [bId, bObj] = _.chain(state.basemaps)
      .entries()
      .first()
      .defaultTo([])
      .value();
    const map = olInitMap({ el, basemap: bObj?.olLayer, initialFocus });
    commit(SET_MAP, map);
    commit(SET_ACTIVE_BASEMAP, bId);
  },
  [REMOVE_INTERACTION_BY_TYPE]({ commit, state }, { type }) {
    state.interactions[type]?.forEach(
      i => state.map.removeInteraction(i)
    );
    commit(CLEAR_INTERACTION_TYPE, type);
  },
  [SET_BASEMAPS]({ commit, state }, { basemaps }) {
    const olLayer = state.basemaps?.[state.activeBasemap]?.olLayer;
    state.map.removeLayer(olLayer);

    const [bId, bObj] = _.chain(basemaps)
      .entries()
      .first()
      .defaultTo([])
      .value();
    state.map.addLayer(bObj.olLayer);

    commit(SET_BASEMAPS_MUTATION, basemaps);
    commit(SET_ACTIVE_BASEMAP, bId);
  },
  [TOGGLE_BASEMAP]({ commit, state }, { basemapId }) {
    const { map, basemaps, activeBasemap } = state;

    if (!(basemapId in basemaps)) return;

    const prevBasemap = basemaps?.[activeBasemap]?.olLayer;
    map.removeLayer(prevBasemap);
    console.log(basemaps[basemapId].olLayer)
    map.addLayer(basemaps[basemapId].olLayer);
    commit(SET_ACTIVE_BASEMAP, basemapId);
  },
};
