import Vue from "vue";
import _ from "lodash";

import {
  SET_WS_DATA,
  SET_NEXT_WS,
  SET_NEXT_WS_EMP,
  SET_DOCS_DATA,
  SET_FIELDS_VALUE,
  SET_FIELD_VALUE_BY_ID,
  ADD_CATALOG_LIST,
  ADD_REGISTRY_CATALOG_LIST,
  RESET,
  SET_FILE_TO_DOC,
  SET_SIGNED_FILE,
  CLEAR_DIRTY_FIELDS_VALUE,
  SET_ERRORS,
  CLEAR_DIRTY_DOCS,
  SET_AUTOFIELD_FUNCTIONS,
  SET_FIELD_DIRTY_BY_ID,
  SET_SUCCESS_GENERATE,
  SET_ERROR_BY_ID,
  TOGGLE_LOADER_MODAL
} from "./mutation-types";

export default {
  [SET_WS_DATA](state, wsData) {
    state.wsData = wsData;
  },
  [SET_NEXT_WS](state, nextWs) {
    state.nextWs = nextWs;
  },
  [SET_NEXT_WS_EMP](state, { wsId, empId }) {
    state.nextWsEmps[wsId] = empId;
  },
  [SET_DOCS_DATA](state, docsData) {
    state.docsData = docsData;
  },
  [SET_FIELDS_VALUE](state, fieldsValue) {
    state.fieldsValue = fieldsValue;
  },
  [SET_ERRORS](state, errors) {
    state.errors = errors;
  },
  [CLEAR_DIRTY_FIELDS_VALUE](state) {
    _.each(state.fieldsValue, (val) => {
      val.dirty = false;
    });
  },
  [SET_FIELD_VALUE_BY_ID](state, { id, value }) {
    if (!(id in state.fieldsValue)) {
      return;
    }

    const fieldValue = state.fieldsValue[id];
    fieldValue.value = value;
    fieldValue.dirty = true;
  },
  [SET_FIELD_DIRTY_BY_ID](state, { id, dirty = false }) {
    if (!(id in state.fieldsValue)) {
      return;
    }

    const fieldValue = state.fieldsValue[id];
    fieldValue.dirty = dirty;
  },
  [SET_ERROR_BY_ID](state, {id, error}) {
    Vue.set(state.errors, id, error);
  },
  [ADD_CATALOG_LIST](state, { catalogId, catalog }) {
    Vue.set(state.catalogsList, catalogId, catalog);
  },
  [ADD_REGISTRY_CATALOG_LIST](state, { catalogName, catalog }) {
    Vue.set(state.registryCatalogsList, catalogName, catalog);
  },
  [SET_FILE_TO_DOC](state, { docId, file }) {
    const doc = state.docsData.input.find(d => d.id === docId);

    Vue.set(doc, "file", file);
    Vue.set(doc, 'dirty', true);
  },
  [SET_SIGNED_FILE](state, { docId, signedFile, type }) {
    const docs = state.docsData[type == 'view' ? 'view' : 'input'];
    const doc = docs.find(d => d.id === docId);
    const file = doc.file;

    Vue.set(file, "signed_files", []);
    Vue.set(doc, 'dirty', true);

    file.signed_files.push(signedFile);
  },
  [CLEAR_DIRTY_DOCS](state) {
    _.each(state.docsData.input, (val) => {
      Vue.set(val, 'dirty', false);
    });
  },
  [RESET](state) {
    state.wsData = {};
    state.nextWs = null;
    state.docsData = {};
    state.fieldsValue = {};
    state.errors = {};
    state.autofieldFunctions = {};
    state.catalogsList = {};
  },
  [SET_AUTOFIELD_FUNCTIONS](state, { functions }) {
    state.autofieldFunctions = functions;
  },
  [SET_SUCCESS_GENERATE](state, successGenerate) {
    state.successGenerate = successGenerate;
  },
  [TOGGLE_LOADER_MODAL](state, loaderModalShow) {
    state.loaderModalShow = loaderModalShow;
  }
};
