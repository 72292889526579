export default function ({ store }) {
  store.commit("setBaseHeaderMenuItem", {
    to: "/base/actualization",
    name: "Актуальные данные",
  });

  // store.commit("setArmHeaderMenuItem", {
  //   to: "/arm/actualization",
  //   name: "Актуальные данные",
  // });
 
  store.commit("setBaseHeaderMenuItem", {
    to: "/base/auction",
    name: "Аукционы",
  });

  store.commit("setArmHeaderMenuItem", {
    to: "/arm/auction",
    name: "Аукционы",
  });

  store.commit("setArmHeaderMenuItem", {
    to: "/arm/actualizationMap",
    name: "Актуальные данные ",
  });

  store.commit("setArmHeaderMenuItem", {
    to: "/arm/registry",
    name: "Реестр заявок",
  });

  store.commit("setArmHeaderMenuItem", {
    to: "/arm/statistica",
    name: "Статистика",
  });
}
