import _ from "lodash";
import {
  GET_INCOME_APPS,
  GET_APPS,
  BOOK_APP
} from "./action-types";

import {
  SET_INCOME_APPS,
  SET_APPS,
  SET_INCOME_APPS_WITH_PAGINATION,
  SET_APPS_WITH_PAGINATION,
  SET_PAGINATION_PAGES_COUNT
} from "./mutation-types";

export default {
  [GET_INCOME_APPS]({ commit, state }) {
    this.$axios.get(`api/app/list/employee/${state.selectedAppStatus}`, {
      params: {
        search: state.searchingValue,
        period: state.selectedAppPeriod,
        page: state.paginationActivePage
      }
    }).then((resp) => {
      commit(SET_INCOME_APPS_WITH_PAGINATION, resp.data);
      commit(SET_INCOME_APPS, resp?.data?.data);
      commit(SET_PAGINATION_PAGES_COUNT, resp?.data?.meta?.last_page)
    });
  },
  [GET_APPS]({ commit, state }) {
    this.$axios.get(`api/app/list/applicant/${state.selectedAppStatus}`, {
      params: {
        search: state.searchingValue,
        period: state.selectedAppPeriod,
        page: state.paginationActivePage
      }
    }).then((resp) => {
      commit(SET_APPS_WITH_PAGINATION, resp.data);
      commit(SET_APPS, resp.data.data);
      commit(SET_PAGINATION_PAGES_COUNT, resp.data.meta.last_page);
    });
  },
  [BOOK_APP]({ dispatch }, { asId }) {
    return this.$axios.post("api/application/book/" + asId).then((resp) => {
        this.$router.push("/arm/application/process/" + asId);
    }).catch((error) => {
      let errMsgData = error?.response?.data;
      let message;
      if (errMsgData != null && typeof errMsgData == "string") {
        message = errMsgData;
      } else {
        message = "wrong_request";
      }
      alert(this.$i18n.t("errors." + message));
    });
  },
};
